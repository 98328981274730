import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import CaseStudy from "../components/caseStudy"
import SignupBanner from "../components/signupBanner"

import AASTestimonal from "../components/testimonials/mike_aas"
import RockItTestimonial from "../components/testimonials/joe_rockit"

import { graphql, Link } from "gatsby" // to query for image data

const AirPage = ({ data }) => (
  <Layout>
    <SEO
      title="Air Cargo Calculator"
      description="Load single ULDs or entire aircrafts at once with our 3D air cargo calculator. Bottom-spacers. Manual Edits. Step-by-step instructions. Try it for free"
    />
    <Hero
      image={data.heroImage}
      title="Air Cargo Calculator"
      body="Create 3D load plans with our ULD calculator or create entire aircraft configurations"
      customers={[
        data.aircanada,
        data.dsv,
        data.kerry,
        data.schenker,
        data.dhl,
      ]}
      className="bg-white"
    />

    <CaseStudy></CaseStudy>
    <RockItTestimonial />

    <FeaturesWithImages
      features={[
        {
          title: "Automatic bottom raise",
          image: data.raisedBottom,
          content: (
            <span>
              Air pallets can often be utilized more if loading for example
              empty pallets in the bottom to raise the load and utilize the
              space above the edges used for attaching the cargo securing nets.
              Set the automatic bottom spacer property and the tool will check
              if this increases the utilization and add this dunnage material
              for you!
            </span>
          ),
        },

        {
          title: "Lower body containers",
          image: data.lowerHoldContainer,
          content: (
            <span>
              Air pallets and containers are usually restricted by the shape of
              the aircraft body, and it can be hard to calculate how to utilize
              these containers. With Cargo-Planner this is not a problem!
            </span>
          ),
        },
        {
          title: "Upper body containers",
          image: data.upperHoldContainer,
          content: (
            <span>
              Using contours at the bottom of the containers makes it possible
              to create lower body containers and the same way for upper body
              contours. See all the pallets and containers we have available in
              our{" "}
              <Link
                to="/equipment-library/pmc/p6-p-ld/"
                className="hover:underline text-blue-900"
              >
                Equipment Library
              </Link>
              .
            </span>
          ),
        },
        {
          title: "Load Aircrafts",
          image: data.loadConfiguration,
          content: (
            <span>
              Do you have entire aircrafts that you want to load? No problem,
              select your aircraft from our library of aircraft models and load
              all ULDs in seconds. Our aim is to make the aircraft loading
              process as simple and stress-free as possible, allowing you to
              focus on other important aspects of your business.
            </span>
          ),
        },
        {
          title: "Special loads",
          image: data.specialLoads,
          content: (
            <span>
              If you have special loads like for example long pieces of cargoes
              that needs to stretch over several positions you can do that as
              well. The tool will make sure it does not intersect with other
              cargoes.
            </span>
          ),
        },
        {
          title: "Build and customize Aircrafts",
          image: data.setBuilder,
          content: (
            <span>
              Cant find a suitable aircraft in our library? You can customize an
              existing aircraft or build your own with our <b>Set Builder</b> to
              make sure that all your requirements are fulfilled. If you need
              any help creating your aircraft, we will gladly assist you.
            </span>
          ),
        },
        {
          title: "Shipping factor",
          image: data.chargeableWeight,
          content: (
            <span>
              A shipping factor can be set to calculate the volumetric weight of
              cargoes which is used to determine the density of the cargo by
              incorporating both dimensions and weight. The volumetric weight is
              compared against the package’s actual weight. The heaviest value
              of these weights is used as the chargeable weight which is
              considered when loading the cargoes.
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
    <AASTestimonal></AASTestimonal>

    <Benefits></Benefits>
    <SignupBanner></SignupBanner>
    {/* <IAGTestimonial /> */}
  </Layout>
)

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "aircrafts/A330-200-2PF.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    aircanada: file(relativePath: { eq: "customers/air-canada.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dhl: file(relativePath: { eq: "customers/dhl.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dsv: file(relativePath: { eq: "customers/dsv.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    kerry: file(relativePath: { eq: "customers/kerry.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    schenker: file(relativePath: { eq: "customers/schenker.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }

    wingedPmc: file(relativePath: { eq: "containers/winged-p6p.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    raisedBottom: file(
      relativePath: {
        eq: "containers/pmc-p6p-with-automatic-bottom-spacer.png"
      }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    upperHoldContainer: file(
      relativePath: { eq: "containers/B767-300F-A1.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(height: 400, quality: 100, layout: CONSTRAINED)
      }
    }
    lowerHoldContainer: file(
      relativePath: { eq: "containers/aaf-with-many-layers.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    loadConfiguration: file(relativePath: { eq: "screens/B767-300F.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
      }
    }
    specialLoads: file(
      relativePath: { eq: "aircrafts/737-maindeck-with-special-loads.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
      }
    }
    setBuilder: file(
      relativePath: { eq: "screens/B767-300F-set-builder.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
      }
    }
    chargeableWeight: file(
      relativePath: { eq: "screens/chargeable-weight.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default AirPage
